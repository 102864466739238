import React from 'react'
import dynamic, { DynamicOptions } from 'next/dynamic'

type LazyImport<T> = () => Promise<{ default: T }>

export const lazyComponent = <T extends React.ComponentType<any>>(
  lazyImport: LazyImport<T>,
  { preload, ssr = false, loading }: { preload?: boolean; ssr?: boolean; loading?: DynamicOptions['loading'] } = {},
) => {
  if (preload) {
    lazyImport()
  }

  return dynamic<T>(lazyImport, { ssr, loading }) as T
}
