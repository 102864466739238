'use client'

import { useToast as useChakraToast } from '@chakra-ui/react'

export const useToast = () => {
  const toast = useChakraToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true,
  })

  return toast
}
