'use client'

import { useEffect } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { useToast } from '@/components/hooks/useToast'

export const useErrorToast = (query: Pick<UseQueryResult, 'error' | 'isError'>) => {
  const toast = useToast()

  useEffect(() => {
    if (query.error) {
      toast({ description: 'Something went wrong. Please try again.', status: 'error' })
    }
  }, [query.error])
}
